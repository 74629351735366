<script>
  import { get, call } from 'vuex-pathify'
  import clone from 'clone-deep'
  import screen from '@/helpers/screen'
  import ExistingMemberModal from '@/components/subscribe/ExistingMemberModal'

  export default {
    components: {
      ExistingMemberModal,
    },
    data() {
      return {
        formRef: null,
        credentials: clone(this.$store.get('account/rememberedCredentials')),
        showExistingMemberModal: false,
      }
    },
    computed: {
      ...get('subscribe', ['waitlistSecondsLeft']),
      ...get('account', ['loggedIn', 'isSubscriptionCanceledWaitlist']),
      screen,
    },
    created() {
      const time = new Date()
      time.setMinutes(time.getMinutes() + 10)
      this.setWaitlistTime({ time })
    },
    async mounted() {
      if (await this.$store.dispatch('account/loggedInAsync')) {
        this.showExistingMemberModal = true
      }
    },
    methods: {
      ...call('subscribe', ['setWaitlistTime', 'joinList']),
      rememberCredentials() {
        this.$store.set('account/rememberedCredentials', clone(this.credentials))
      },
      joinWaitlist() {
        this.rememberCredentials()
        this.joinList({ listId: '4ba2ae8128', email: this.credentials.username })
        this.$emit('navigateToSubscribe')
      },
      dismissModal() {
        const route = this.isSubscriptionCanceledWaitlist
          ? { name: 'AccountProfileSubscriptions', params: { section: 'reactivate' } }
          : { name: 'Home' }
        this.$navigate(route)
      },
    },
  }
</script>

<template>
  <div class="bg-dawn-lt2">
    <div class="xl:grid xl:grid-cols-2">
      <BaseImage
        v-if="!screen.xl"
        :src="`/static/subscribe/waitlist_landing_hero_${screen.md ? 'tablet' : 'mobile'}.jpg`"
        alt="hero"
        responsive="md"
      />
      <div class="flex justify-center items-center bg-dawn">
        <div class="pb-5 xl:pb-0" style="max-width: 330px">
          <BaseCountdownPill :seconds-left="waitlistSecondsLeft" class="-mt-38px xl:mt-0" />
          <h2 class="mt-4 xl:mt-7 font-heading font-semibold leading-none text-center">
            <span class="text-4xl lg:text-5xl">DON’T MISS OUT</span>
            <br />
            <span class="text-8xl lg:text-9xl">
              JOIN THE <br />
              WAITLIST
            </span>
          </h2>
          <BaseForm
            v-slot="{ submitEnabled }"
            v-ref="formRef"
            class="px-2 2xs:px-0"
            @submit="joinWaitlist"
          >
            <BaseInput
              v-model="credentials.username"
              type="email"
              name="username"
              required
              class="mt-7px lg:mt-14px"
              v-on="$listeners"
            >
              Email
            </BaseInput>
            <div class="mt-1 mb-2">
              <BaseIcon :size="4" class="text-primary inline-block align-middle">
                <IconInfoAlertOutline />
              </BaseIcon>
              <span class="text-primary font-medium text-3xs">Waitlist is for new members!</span>
            </div>
            <BaseButton type="submit" color="black" :disabled="!submitEnabled || loggedIn">
              Join the waitlist
            </BaseButton>
          </BaseForm>
          <p class="mt-12px max-w-82 mx-auto text-3xs leading-snug-tight text-center text-primary">
            By clicking “Join the waitlist” you are agreeing to the Alltrue
            <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank">
              Terms of Use
            </BaseLinkStyled>
            and
            <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank">
              Privacy Policy </BaseLinkStyled
            >. You will receive emails periodically, opt-out at any time.
          </p>
        </div>
      </div>
      <BaseImage
        v-if="screen.xl"
        src="/static/subscribe/waitlist_landing_hero_desktop.jpg"
        alt="hero"
        responsive="lg"
      />
    </div>
    <div class="flex flex-col-reverse xl:grid xl:grid-cols-2 xl:gap-x-4 pt-4">
      <BaseImage
        src="/static/subscribe/waitlist_landing_grid.jpg"
        alt="hero"
        background-transparent
        responsive="lg"
        class="pt-4 xl:pt-0"
      />
      <div
        class="px-2 2xs:px-0 flex flex-col justify-center items-center text-center bg-dawn pt-46px pb-8 xl:py-0"
      >
        <span class="font-semibold text-3xs xl:text-base" style="line-height: 1.2">
          JOIN TODAY &#8729; LIMITED BOXES AVAILABLE
        </span>
        <div
          class="font-heading font-semibold text-4xl xl:text-48px leading-none my-4 xl:mt-10px xl:mb-5"
        >
          Reserve the next box <br />
          before we sell out!
        </div>
        <p class="max-w-78 md:max-w-115 font-medium text-md md:text-xl" style="line-height: 1.2">
          Join today to make sure you don't miss the next release! Receive up to $250+ value worth
          of products and choose from hundreds of combos.
        </p>
      </div>
    </div>

    <Portal to="modal">
      <ExistingMemberModal :open="showExistingMemberModal" @dismiss="dismissModal" />
    </Portal>
  </div>
</template>
