<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** Type of membership. */
      type: {
        type: String,
        required: true,
        validator: (value) => ['quarterly', 'annually', 'alltruePlus'].includes(value),
      },
      isForSignupView: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        benefitsAccordionClicked: false,
      }
    },
    computed: {
      ...get('prismic', ['getSubscribePlanCardByType']),
      screen,
      typeIsSeasonal() {
        return this.type === 'quarterly'
      },
      typeIsAnnual() {
        return this.type === 'annually'
      },
      typeIsAlltruePlus() {
        return this.type === 'alltruePlus'
      },
      color() {
        switch (this.type) {
          case 'quarterly':
            return 'summer'
          case 'annually':
            return 'primary'
          default:
            return 'spring'
        }
      },
      typeLabel() {
        switch (this.type) {
          case 'quarterly':
            return 'Seasonal'
          case 'annually':
            return 'Annual'
          default:
            return 'Alltrue+'
        }
      },
      buttonCta() {
        switch (this.type) {
          case 'quarterly':
            return 'Select'
          default:
            return 'Select'
        }
      },
      planData() {
        return this.getSubscribePlanCardByType(this.type)
      },
      price() {
        return this.planData?.price
      },
      priceByline() {
        return this.planData?.price_byline[0].text
      },
      benefitsHeader() {
        return this.planData?.benefits_header
      },
      mobileBenefitsPreview() {
        return this.planData?.mobile_benefits_preview
      },
      benefits() {
        const quarterlyBenefits = this.getSubscribePlanCardByType('quarterly')?.benefits
        if (!this.screen.sm) {
          return this.type === 'quarterly'
            ? this.planData?.benefits
            : [...this.planData?.benefits, ...quarterlyBenefits]
        }
        return this.planData?.benefits
      },
      renewsAt() {
        return this.planData?.renews_at?.[0].text
      },
      showBenefits() {
        if (this.screen.sm) {
          if (this.isForSignupView && screen.lg) {
            return false
          } else if (this.isForSignupView) {
            return this.benefitsAccordionClicked
          }
          return true
        } else {
          return this.benefitsAccordionClicked
        }
      },
      showMobileBenefitsPreview() {
        return (
          !this.screen.sm &&
          !this.typeIsSeasonal &&
          !this.isForSignupView &&
          this.mobileBenefitsPreview?.length
        )
      },
      numberOfGiftIcons() {
        if (this.typeIsSeasonal) {
          return 1
        }
        return 4
      },
      benefitsFontSize() {
        if (this.screen.sm && !this.screen.lg && !this.isForSignupView) {
          return '3xs'
        } else if (this.screen.lg && !this.screen.xl) {
          return '2xs'
        }
        return 'sm'
      },
    },
    created() {
      this.loadSubscribeFlowData()
    },
    methods: {
      ...call('prismic', ['loadSubscribeFlowData']),
    },
  }
</script>

<template>
  <div
    :class="`bg-dawn w-full ${
      isForSignupView ? '' : 'sm:max-w-49 md:max-w-58 lg:max-w-77 xl:max-w-88'
    }`"
  >
    <div
      :class="`${
        isForSignupView && screen.lg
          ? 'border-t border-l border-r rounded-t-md'
          : 'border rounded-md'
      } border-black`"
    >
      <!-- Kicker with type of membership and icons -->
      <!-- for purgecss: bg-dawn bg-primary bg-spring -->
      <div
        :class="`flex justify-between items-center border-b border-black rounded-t-md ${
          (benefitsAccordionClicked && !screen.sm) || isForSignupView
            ? typeIsAnnual
              ? `bg-${color} text-dawn`
              : `bg-${color}`
            : ''
        }`"
      >
        <span
          :class="`font-semibold leading-close ml-4 ${
            isForSignupView
              ? ''
              : 'sm:ml-11px md:ml-13px lg:ml-4 sm:text-4xs md:text-2xs lg:text-base'
          }`"
        >
          {{ typeLabel }}
          <span
            v-if="typeIsAlltruePlus"
            :class="`font-bold text-2xs ${
              isForSignupView ? '' : 'sm:text-8px md:text-4xs lg:text-2xs'
            }`"
          >
            &#8729; NEW
          </span>
        </span>
        <div class="flex items-center mr-1">
          <template v-if="typeIsAlltruePlus">
            <BaseIcon :class="`w-5 ${isForSignupView ? '' : 'sm:w-4 lg:w-5'}`">
              <IconShirt />
            </BaseIcon>
            <span :class="`mx-1 text-base ${isForSignupView ? '' : 'sm:text-9px lg:text-base'}`">
              +
            </span>
          </template>
          <BaseIcon
            v-for="index in numberOfGiftIcons"
            :key="index"
            :class="`mr-1 w-4 ${isForSignupView ? '' : 'sm:w-11px md:w-13px lg:w-4'}`"
          >
            <IconBox />
          </BaseIcon>
        </div>
      </div>
      <!-- Price and billing details -->
      <div
        :class="`flex flex-col ${
          isForSignupView
            ? screen.lg
              ? 'pt-14px pb-10px pl-6'
              : 'pt-14px pb-10px items-center'
            : 'border-b border-black py-5 sm:py-3 md:py-4 lg:py-5 items-center'
        }`"
      >
        <div class="flex">
          <!-- Price -->
          <span
            v-if="price"
            :class="`font-heading font-semibold text-6xl ${
              isForSignupView ? '' : 'sm:text-30px md:text-5xl lg:text-6xl'
            } leading-tighter`"
          >
            {{ $formatPrice(price) }}
          </span>
          <!-- Badge for non-quarterly plans -->
          <div
            v-if="!typeIsSeasonal"
            :class="`ml-5px ${
              typeIsAlltruePlus ? 'bg-black text-sunshine' : 'bg-sunshine'
            } rounded-full w-12 h-12 ${
              isForSignupView ? '' : 'sm:w-8 md:w-10 lg:w-12 sm:h-8 md:h-10 lg:h-12'
            } font-heading font-bold leading-heading text-center flex justify-center items-center`"
          >
            <span
              v-if="typeIsAnnual"
              :class="`text-xs ${
                isForSignupView ? '' : 'sm:text-9px md:text-3xs lg:text-xs'
              } mt-1px`"
            >
              TOP <br />
              PICK
            </span>
            <div v-if="typeIsAlltruePlus" class="sm:ml-1px md:mt-2px" style="line-height: 0.7">
              <span
                :class="`text-17px ${isForSignupView ? '' : 'sm:text-3xs md:text-sm lg:text-17px'}`"
              >
                VIP
              </span>
              <div
                :class="`mt-2px lg:mt-1 text-4xs ${
                  isForSignupView ? '' : 'sm:text-6px md:text-8px lg:text-4xs'
                }`"
              >
                PERKS
              </div>
            </div>
          </div>
        </div>
        <div
          :class="`font-medium leading-close flex flex-col ${
            isForSignupView && screen.sm ? '' : 'items-center'
          }`"
        >
          <!-- Subtext under price -->
          <div
            v-if="priceByline"
            :class="`text-sm ${isForSignupView ? '' : ' sm:text-3xs md:text-2xs lg:text-sm'}`"
          >
            {{ priceByline }}
          </div>

          <!-- Extra benefits information for non-quarterly plans -->
          <BaseList
            v-if="showMobileBenefitsPreview"
            type="check"
            gap-size="sm"
            font-size="2xs"
            leading="close"
            class="font-normal mt-6px"
          >
            <BaseListItem
              v-for="({ spans, text }, index) in mobileBenefitsPreview"
              :key="index"
              :emphasis="Boolean(spans.length && spans[0].type === 'strong')"
            >
              {{ text }}
            </BaseListItem>
          </BaseList>
        </div>
        <!-- 'VIEW PLAN' text above accordion on mobile -->
        <div
          v-if="!screen.sm && !isForSignupView"
          class="absolute bottom-0 right-0 mr-10px mb-7px font-medium text-2xs"
          style="line-height: 1.08"
        >
          VIEW <br />
          INFO
        </div>
        <div
          v-else-if="!screen.lg && isForSignupView"
          class="absolute bottom-0 right-0 font-medium text-2xs"
          style="line-height: 1.08"
        >
          <div class="text-center mb-6px">
            VIEW <br />
            INFO
          </div>
          <!-- Accordion to open/close benefits on mobile -->
          <button
            v-if="!screen.lg"
            class="min-w-46px min-h-46px flex items-center justify-center border-l border-t border-black rounded-tl-md rounded-br-md"
            @click="benefitsAccordionClicked = !benefitsAccordionClicked"
          >
            <BaseIcon :size="5">
              <IconChevronDownThick v-if="!benefitsAccordionClicked" />
              <IconChevronUpThick v-else />
            </BaseIcon>
          </button>
        </div>
      </div>
      <div v-if="!isForSignupView" class="flex">
        <!-- for purgecss: bg-dawn bg-primary bg-spring -->
        <!-- Button with CTA to select plan -->
        <button
          :class="`group w-full cta-border-radius py-14px sm:py-2 md:py-3 lg:py-14px font-semibold leading-close sm:text-2xs lg:text-base text-center bg-${color} ${
            color === 'primary' ? 'text-dawn' : ''
          }`"
          @click="$emit('selectPlan')"
        >
          {{ buttonCta }}
          <BaseIcon
            class="inline-block align-middle mb-2px ml-6px transform duration-400 group-hover:translate-x-2 w-10px lg:w-3"
          >
            <IconChevronRightThick />
          </BaseIcon>
        </button>
        <!-- Accordion to open/close benefits on mobile -->
        <button
          v-if="!screen.sm"
          class="min-w-50px flex items-center justify-center border-l border-black"
          @click="benefitsAccordionClicked = !benefitsAccordionClicked"
        >
          <BaseIcon :size="5">
            <IconChevronDownThick v-if="!benefitsAccordionClicked" />
            <IconChevronUpThick v-else />
          </BaseIcon>
        </button>
      </div>
    </div>
    <!-- Benefits (always open on desktop, toggle via accordion on mobile) -->
    <div
      v-if="showBenefits"
      :class="`mt-6px sm:mt-2 border border-black rounded-md ${
        typeIsAlltruePlus || isForSignupView ? '' : 'sm:min-h-70 md:min-h-64 xl:min-h-72'
      }`"
    >
      <div class="py-4 sm:py-3 lg:py-4 px-4 sm:px-2 lg:px-4">
        <div
          v-if="!typeIsSeasonal && !isForSignupView && screen.sm"
          :class="`text-center font-bold text-${benefitsFontSize} mb-4`"
          style="line-height: 1.1"
        >
          <PrismicRichText v-if="benefitsHeader" :field="benefitsHeader" />
        </div>
        <BaseList type="check" gap-size="lg" :font-size="benefitsFontSize" leading="close">
          <BaseListItem
            v-for="({ spans, text }, index) in benefits"
            :key="index"
            :emphasis="Boolean(spans.length && spans[0].type === 'strong')"
          >
            <!-- We want to style the second line if there is one, but we can't do that directly in Prismic
             so we're looking for a line break and manually adding a custom style to it -->
            <span>{{ text.split('\n')[0] }}</span>
            <template v-if="text.split('\n')[1]">
              <br />
              <span class="text-2xs font-normal">{{ text.split('\n')[1] }}</span>
            </template>
          </BaseListItem>
          <BaseListItem>
            {{ renewsAt }}
          </BaseListItem>
        </BaseList>
      </div>
      <!-- T-shirt banner for Alltrue+ plan -->
      <div
        v-if="typeIsAlltruePlus"
        class="bg-spring px-5 lg:pr-3 xl:pr-0 rounded-b-md py-2 flex sm:flex-col lg:flex-row items-center justify-evenly"
      >
        <div class="flex flex-col items-start sm:items-center lg:items-start">
          <span class="border border-black font-bold text-2xs rounded leading-close px-2 py-3px">
            EXCLUSIVE
          </span>
          <p
            class="mt-6px font-heading font-semibold text-26px sm:text-xl lg:text-2xl xl:text-26px sm:text-center lg:text-left"
            style="line-height: 1.07"
          >
            Members <br />
            Only Alltrue+ <br />
            T-shirt
          </p>
        </div>
        <div class="tshirt-image">
          <BaseImage
            src="/static/subscribe/tshirt-transparent-back.png"
            alt="tshirt"
            class="max-w-37"
            background-transparent
            responsive="xs"
          />
          <BaseImage
            src="/static/subscribe/tshirt-transparent-front.png"
            alt="tshirt"
            class="max-w-37 tshirt-front"
            background-transparent
            responsive="xs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */
  .cta-border-radius {
    border-bottom-left-radius: 0.4rem;

    @screen sm {
      border-bottom-right-radius: 0.4rem;
    }
  }

  .tshirt-front {
    @apply absolute hidden top-0 left-0 z-20;
  }

  .tshirt-image:hover .tshirt-front {
    @screen sm {
      @apply inline;
    }
  }

  /* purgecss end ignore */
</style>
