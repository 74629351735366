<script>
  import { get, sync, call } from 'vuex-pathify'
  import { events } from '@/helpers/gtm'
  import SelectPlanCard from '@/components/subscribe/SelectPlanCard'
  import LogoAlltrue from '@/images/logos/LogoAlltrue.svg'
  import screen from '@/helpers/screen'
  import SubscribeWaitlistLandingPage from '@/components/subscribe/SubscribeWaitlistLandingPage'

  export default {
    components: {
      SelectPlanCard,
      LogoAlltrue,
      SubscribeWaitlistLandingPage,
    },
    computed: {
      ...get('config', ['isCurrentBoxWaitlist']),
      ...get('feature', ['flags']),
      ...get('account', ['isAlltruePlusSubscriber']),
      ...sync('subscribe', ['selectedPlanHandle']),
      screen,
      showAlltruePlus() {
        const isAllTruePlusEnabled = this.flags['alltrue-plus-option'] ?? false
        return isAllTruePlusEnabled && !this.isAlltruePlusSubscriber
      },
    },
    created() {
      events.log({ name: 'viewed plan selection' })
      events.startSubscriptionCheckoutFunnel({ email: this.username })
      if (!this.isAlltruePlusSubscriber) {
        this.observeFlag('alltrue-plus-option')
      }
    },
    methods: {
      ...call('feature', ['observeFlag']),
      selectPlan(planHandle) {
        this.selectedPlanHandle = planHandle
        events.log({
          name: 'selected plan',
          data: { plan: planHandle },
        })
        events.selectedSubscriptionPlan({
          email: this.username,
          planHandle,
        })
        this.$navigate({ name: 'SubscribeSignup', params: { subscribe: 'subscribe' } })
      },
    },
  }
</script>

<template>
  <div>
    <SubscribeWaitlistLandingPage
      v-if="isCurrentBoxWaitlist"
      @navigateToSubscribe="selectPlan('quarterly')"
    />
    <div v-else class="mb-12">
      <!-- Banner with header -->
      <div class="bg-black text-dawn py-6 px-2 text-center text-sm leading-snug-tight">
        <h1 class="font-semibold text-base md:text-lg leading-snug-tight">Select a Membership</h1>
        <p> Limited quantities available. Cancel anytime. </p>
        <p> Free shipping.* </p>
      </div>
      <!-- Plan cards -->
      <div
        class="flex flex-col sm:flex-row justify-center px-3 sm:px-4 md:px-6 xl:px-0 mt-4 lg:mt-6 mb-6"
      >
        <SelectPlanCard type="quarterly" @selectPlan="selectPlan('quarterly')" />
        <SelectPlanCard
          type="annually"
          class="my-4 sm:my-0 sm:ml-3 lg:ml-6"
          @selectPlan="selectPlan('annually')"
        />
        <SelectPlanCard
          v-if="showAlltruePlus"
          type="alltruePlus"
          class="sm:ml-3 lg:ml-6"
          @selectPlan="selectPlan('alltruePlus')"
        />
      </div>
      <div :class="`max-width-two-cards ${showAlltruePlus ? 'max-width-three-cards' : ''} mx-auto`">
        <BaseDivider color="black" class="mb-6" />
        <div v-if="!screen.lg" class="flex items-center justify-center mb-4">
          <a
            class="max-h-11 flex items-center justify-center border border-black p-2 rounded-sm w-full"
            href="https://alltrue.com/gift"
          >
            <div class="bg-black h-7 flex items-center px-1" style="border-radius: 3px">
              <BaseIcon class="text-dawn">
                <LogoAlltrue class="w-8" />
              </BaseIcon>
            </div>
            <div class="font-bold text-2xs ml-2 mr-6px leading-close">
              Gifting? Learn more here
            </div>
            <BaseIcon :size="3" class="text-black">
              <IconChevronRightThick />
            </BaseIcon>
          </a>
        </div>
        <p class="text-sm lg:text-base text-center leading-close italic lg:not-italic">
          *Free shipping only available in the contiguous USA. $7.95 shipping Canada, Hawaii,
          Alaska, and US Territories.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .max-width-two-cards {
    @apply px-3;

    @screen sm {
      @apply px-0;

      max-width: 404px;
    }

    @screen md {
      max-width: 476px;
    }

    @screen lg {
      max-width: 640px;
    }

    @screen xl {
      max-width: 728px;
    }
  }

  .max-width-three-cards {
    @apply px-3;

    @screen sm {
      @apply px-0;

      max-width: 612px;
    }

    @screen md {
      max-width: 720px;
    }

    @screen lg {
      max-width: 990px;
    }

    @screen xl {
      max-width: 1104px;
    }
  }
</style>
