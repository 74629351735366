<script>
  import { get } from 'vuex-pathify'

  export default {
    props: {
      open: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      ...get('account', ['isSubscriptionCanceledWaitlist']),
    },
  }
</script>

<template>
  <BaseModal
    :open="open"
    no-horizontal-padding
    no-vertical-padding
    size="lg"
    height="md"
    bg-color="dawn-lt1"
    dismissible
    @dismiss="$emit('dismiss')"
  >
    <div class="bg-white">
      <BaseImage
        src="/static/subscribe/waitlist_existing_member_illustration.gif"
        alt=""
        background-transparent
        responsive="sm"
        class="w-70 md:w-90 mx-auto py-11"
      />
    </div>
    <div class="py-8 md:pt-16 md:pb-0 text-center">
      <h2 class="font-heading text-2xl md:text-6xl leading-none px-2 md:px-14 lg:px-18">
        It looks like you’re already a part of the Alltrue Community!
      </h2>
      <p
        v-if="!isSubscriptionCanceledWaitlist"
        class="mt-13px text-base md:text-2xl px-4 md:px-28"
        style="line-height: 1.2"
      >
        The Waitlist is for new members only. Don’t worry, you’ll still get the next box!
      </p>
      <BaseButton class="mt-8 w-64 mx-auto" @click="$emit('dismiss')">
        <span v-if="isSubscriptionCanceledWaitlist"> Reactivate Membership </span>
        <span v-else> OKAY </span>
      </BaseButton>
    </div>
  </BaseModal>
</template>
